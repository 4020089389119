import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Breadcrumbs,
  Grid,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  pageTitle: {
    fontWeight: 600
  },
}));

const Header = ({
  className,
  title,
  breadcrumbs,
  action,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid
        container
        spacing={3}
        justify="space-between"
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Grid item>
          {
            breadcrumbs && breadcrumbs.length > 0 ?
            <Breadcrumbs
              aria-label="breadcrumb"
            >
              {breadcrumbs.map(breadcrumb => (
                <Link
                  key={breadcrumb.title}
                  variant="body2"
                  color="inherit"
                  onClick={breadcrumb.onClick}
                  href="#"
                  // to={process.env.PUBLIC_URL + breadcrumb.to}
                  // component={RouterLink}
                >
                  {breadcrumb.title}
                </Link>
              ))}
              <Typography
                variant="body2"
                color="textPrimary"
              >
                {title}
              </Typography>
            </Breadcrumbs> :
            null
          }
          <Typography
            className={classes.pageTitle}
            variant="h1"
            color="textPrimary"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          {action}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  action: PropTypes.element
};

export default Header;
