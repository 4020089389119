/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
// import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// Currently we only install react-router for the `matchPath` function
import { matchPath } from 'react-router';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles
} from '@material-ui/core';
import Logo from '../Logo';
import NavItem from './NavItem';

function renderNavItems({
  items,
  pathname,
  depth = 0
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        key={key}
        depth={depth}
        icon={item.icon}
        info={item.info}
        title={item.title}
        open={Boolean(open)}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    const escapedPath =
      item.href && item.href.replace(/([.+*?=^!:${}()[\]|/\\])/g, "\\$1");

    const match = escapedPath
      ? matchPath(pathname, { path: escapedPath })
      : null;

    acc.push(
      <NavItem
        key={key}
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
        isActive={!!(match)}
        isExternal={item.isExternal}
        onClick={item.onClick}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 240
  },
  desktopDrawer: {
    width: 240,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    width: 42,
    height: 42,
    padding: theme.spacing(0.5),
    marginRight: theme.spacing(1),
    fontWeight: 600,
    border: '1px solid rgba(0, 0, 0, 0.12)'
  },
  headerSubtitle: {
    fontSize: '0.75rem'
  },
  back: {
    color: theme.palette.text.secondary,
  }
}));

const NavBar =  ({
  sections,
  activePath,
  // headerTitle,
  // backText,
  // backTo,
  logo,
  header,
  footer,
  onMobileClose,
  openMobile,
  // isLoaded
}) => {
  const classes = useStyles();
  // const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePath]);

  const appLogo = logo ? logo : <Logo />;

  // const header = headerTitle ? (
  //   <React.Fragment>
  //     <Box p={2}>
  //       <Box
  //         display="flex"
  //         alignItems="center"
  //         justifyContent="left"
  //       >
  //         <AvatarWithSkeleton
  //           mr
  //           variant="rounded"
  //           className={classes.avatar}
  //           isLoaded={isLoaded}
  //           src={`${process.env.PUBLIC_URL}/static/sit-logo.svg`}
  //           imgProps={{
  //             style: { objectFit: 'contain' }
  //           }}
  //         >
  //           {isLoaded ? getInitials(headerTitle) : null}
  //         </AvatarWithSkeleton>
  //         <Box width={1}>
  //           <Typography
  //             variant="h6"
  //             color="textPrimary"
  //           >
  //             {isLoaded ? headerTitle : <Skeleton />}
  //           </Typography>
  //           <Typography
  //             className={classes.headerSubtitle}
  //             variant="body2"
  //             color="textSecondary"
  //           >
  //             Brisbane, Australia
  //           </Typography>
  //         </Box>
  //       </Box>
  //       {
  //         backTo ?
  //         <Box
  //           mt={1}
  //         >
  //           <NavItem
  //             className={classes.back}
  //             depth={0}
  //             href={backTo}
  //             icon={ArrowLeftIcon}
  //             key={'back'}
  //             title={backText}
  //             preventActiveHighlighting
  //           />
  //         </Box> : null
  //       }
  //     </Box>
  //   </React.Fragment>
  // ) : null;

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            {appLogo}
          </Box>
        </Hidden>
        {header}
        <Box
          pt={header ? 0 : 2}
          px={2}
        >
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={(
                <ListSubheader
                  disableGutters
                  disableSticky
                >
                  {section.subheader}
                </ListSubheader>
              )}
            >
              {renderNavItems({
                items: section.items,
                pathname: activePath
              })}
            </List>
          ))}
        </Box>
        <Box
          width="100%"
          position="absolute"
          bottom="0"
        >
          {footer}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <React.Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </React.Fragment>
  );
};

NavBar.propTypes = {
  sections: PropTypes.array,
  activePath: PropTypes.string,
  // headerTitle: PropTypes.string,
  // backText: PropTypes.string,
  // backTo: PropTypes.string,
  logo: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  // isLoaded: PropTypes.bool,
};

export default NavBar;
