import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Avatar,
  makeStyles
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import ConditionalWrapper from './ConditionalWrapper';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    height: 42,
    width: 42,
  },
  mr: {
    marginRight: theme.spacing(1)
  }
}));

const AvatarWithSkeleton = ({
  children,
  isLoaded,
  height = 42,
  width = 42,
  mr,
  ...rest
}) => {
  const classes = useStyles();

  const avatar = isLoaded ?
    <Avatar {...rest}>{children}</Avatar> :
    <Avatar {...rest} />;

  const skeletonClasses = clsx({
    [classes.skeleton]: true,
    [classes.mr]: mr,
  });

  return (
    <ConditionalWrapper
      condition={!isLoaded}
      wrapper={children =>
        <Skeleton
          className={skeletonClasses}
          variant="circle"
          height={`${height}px`}
          width={`${width}px`}
        >
          {children}
        </Skeleton>
      }
    >
      {avatar}
    </ConditionalWrapper>
  );
};

AvatarWithSkeleton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isLoaded: PropTypes.bool.isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  mr: PropTypes.bool
};

export default AvatarWithSkeleton;
