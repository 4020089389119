import React from 'react';
import PropTypes from 'prop-types';
import {
  Breadcrumbs,
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Page from './Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  breadcrumbsLi: {
    width: '100%'
  },
  card: {
    maxWidth: '970px'
  },
}));

const SkeletonPage = ({ className, ...rest }) => {
  const classes = useStyles();

  return (
    <Page
      className={classes.root}
      title="Loading..."
    >
      <Container maxWidth={false}>
        <Grid
          className={classes.header}
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <Breadcrumbs
              aria-label="breadcrumb"
              classes={{ li: classes.breadcrumbsLi }}
            >
              <Skeleton width="10%" />
            </Breadcrumbs>
            <Typography
              variant="h2"
              color="textPrimary"
            >
              <Skeleton width="20%" />
            </Typography>
          </Grid>
        </Grid>
        <Box mt={3}>
          <Card className={classes.card}>
            <CardContent>
              <Skeleton />
              <Skeleton />
              <Skeleton width="70%" />
            </CardContent>
          </Card>
        </Box>
      </Container>
    </Page>
  );
}

SkeletonPage.propTypes = {
  className: PropTypes.string
};

export default SkeletonPage;
