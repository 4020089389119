import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ChoiceAutocomplete = ({
  options,
  fieldName,
  fieldValue,
  setFieldValue,
  ...rest
}) => {
  const { multiple } = { ...rest };

  const getValue = () => {
    const value = multiple
      ? options.filter(o => fieldValue.includes(o.value))
      : options.find(o => o.value === fieldValue) || null;
    return value;
  };

  const handleChange = (_, value) => {
    let fieldValue;
    if (multiple) {
      fieldValue = value.length ? value.map(v => v.value) : [];
    } else {
      fieldValue = value ? value.value  : "";
    }
    setFieldValue(fieldName, fieldValue)
  };

  return (
    <Autocomplete
      getOptionLabel={(option) => option.display_name || ""}
      getOptionSelected={(option, value) => option === value}
      options={options}
      value={getValue()}
      onChange={handleChange}
      renderInput={(params) => null}
      {...rest}
    />
  );
};

ChoiceAutocomplete.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  fieldName: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func
};

export default ChoiceAutocomplete;
