import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  CardContent,
  makeStyles
} from '@material-ui/core';
import LoadingScreen from './LoadingScreen';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(10),
    "&:last-child": {
      padding: theme.spacing(10),
    }
  },
}));

const CardContentWithLoader = ({
  className,
  isLoaded,
  children,
  ...rest
}) => {
  const classes = useStyles();

  const cardContentClasses = clsx({
    [classes.root]: !isLoaded,
    [className]: true
  });

  return (
    <CardContent
      className={cardContentClasses}
      {...rest}
    >
      {isLoaded ? children : <LoadingScreen />}
    </CardContent>
  );
};

CardContentWithLoader.propTypes = {
  className: PropTypes.string,
  isLoaded: PropTypes.bool.isRequired,
  children: PropTypes.node
};

export default CardContentWithLoader;
