import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import NProgress from 'nprogress';
import {
  Box,
  LinearProgress,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    left: 0,
    top: 0,
    width: '100%',
    zIndex: 2000
  }
}));

const LoadingScreen = ({
  className,
  ...rest
}) => {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <Box width={325}>
        <LinearProgress />
      </Box>
    </div>
  );
};

LoadingScreen.propTypes = {
  className: PropTypes.string
};

export default LoadingScreen;
