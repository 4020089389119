import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';

const ObjectAutocomplete = ({
  className,
  options,
  optionLabelName,
  fieldName,
  fieldValue,
  setFieldValue,
  renderInput,
  ...rest
}) => {
  const { multiple } = { ...rest };

  const getValue = () => {
    const value = multiple
      ? options.filter(o => fieldValue.includes(o.id))
      : options.find(choice => choice.id === fieldValue) || null;
    return value;
  };

  const handleChange = (_, value) => {
    let fieldValue;
    if (multiple) {
      fieldValue = value.length ? value.map(v => v.id) : [];
    } else {
      fieldValue = value ? value.id  : "";
    }
    setFieldValue(fieldName, fieldValue)
  };

  return (
  <Autocomplete
    className={className}
    getOptionLabel={(option) => option[optionLabelName] || ""}
    getOptionSelected={(option, value) => option === value}
    options={options}
    value={getValue()}
    onChange={handleChange}
    renderInput={renderInput}
    {...rest}
  />
)};

ObjectAutocomplete.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array.isRequired,
  optionLabelName: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  fieldValue: PropTypes.any.isRequired,
  setFieldValue: PropTypes.func,
  renderInput: PropTypes.func
};

export default ObjectAutocomplete;
