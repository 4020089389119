import React from 'react';
import { ThemeProvider } from '@material-ui/core';
import useSettings from './hooks/useSettings';
import { createTheme } from './theme';

const App = ({ children }) => {
  const { settings } = useSettings();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

export default App;
