import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import {
  Button,
  Checkbox,
  ListItemText,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import {
  ChevronDown as ChevronDownIcon
} from 'react-feather';
import toggleArrayElement from '../utils/toggleArrayElement';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 400,
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  filterPlaceholder: {
    color: theme.palette.text.secondary
  },
  menu: {
    width: 210,
    maxWidth: '100%',
    maxHeight: 250
  },
  checkbox: {
    transform: "scale(0.8)",
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-0.5)
  }
}));

const SelectFilter = ({
  className,
  filterName,
  label,
  options,
  selectedOptions,
  getOptionLabelName,
  setFilters,
  ...rest
}) => {
  const classes = useStyles();
  const anchorRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleFilterChange = (event, filterName, option) => {
    event.persist();

    const clickedOption = getOptionLabelName(option);
    const newSelectedOptions = toggleArrayElement(selectedOptions, clickedOption);

    setFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: newSelectedOptions
    }));
  };

  return (
    <React.Fragment>
      <Button
        className={clsx(classes.root, className, {
          [classes.active]: selectedOptions.length > 0
        })}
        variant="outlined"
        onClick={handleMenuOpen}
        ref={anchorRef}
        endIcon={<ChevronDownIcon size="12" strokeWidth="3"/>}
      >
        {label}
      </Button>
      <Menu
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{ className: classes.menu }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {options.map(option => (
          <MenuItem
            key={`${label}-${option.id}`}
            onClick={(e) => handleFilterChange(e, filterName, option)}
          >
            <Checkbox
              className={classes.checkbox}
              checked={selectedOptions.indexOf(getOptionLabelName(option)) > -1}
              disableRipple
              disableFocusRipple
              disableTouchRipple
            />
            <ListItemText
              primary={getOptionLabelName(option)}
              primaryTypographyProps={{
                variant: 'body2',
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default SelectFilter;
