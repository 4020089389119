import _ from 'lodash';
import {
  colors,
  createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import { THEMES } from '../constants';
import { softShadows, strongShadows } from './shadows';
import typography from './typography';

const baseOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiInputBase: {
      root: {
        fontSize: '0.875rem',
        height: '36px'
      },
      multiline: {
        height: 'auto'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '9.25px 14px'
      },
      notchedOutline: {
        borderColor: 'rgba(0, 0, 0, 0.12)'
      }
    },
    MuiInputLabel: {
      outlined: {
        fontSize: '0.875rem',
        transform: 'translate(14px, 10px) scale(1)'
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"]': {
          padding: 0,
          paddingLeft: '15px'
        },
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: 0,
          height: '100%',
          '&:first-child': {
            paddingLeft: 0
          }
        }
      },
      option: {
        fontSize: '0.875rem',
      },
      noOptions: {
        fontSize: '0.875rem',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
      },
      contained: {
        padding: '6px 16px'
      },
      outlined: {
        border: '1px solid rgba(0, 0, 0, 0.12)'
      }
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        color: '#78909c',
        minWidth: 32
      }
    },
    MuiIconButton: {
      root: {
        color: '#78909c'
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
        borderRadius: 4,
        fontSize: '0.75rem'
      }
    },
    MuiFormHelperText: {
      root: {
        '&:first-letter': {
          textTransform: 'capitalize'
        },
      }
    },
    MuiTableCell: {
      body: {
        height: '53px',
        padding: '4px 16px',
        borderBottom: 'none'
      }
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0 0 1px 0 rgba(0,0,0,0.05), 0 3px 4px -2px rgba(0,0,0,0.10)'
      }
    },
    MuiCardHeader: {
      root: {
        padding: '6px 16px',
        minHeight: '53px'
      },
      action: {
        marginTop: 0
      }
    },
    MuiMenuItem: {
      root: {
        padding: '16px',
        paddingTop: '4px',
        paddingBottom: '4px',
        fontSize: '0.875rem'
      }
    },
    MuiBreadcrumbs: {
      separator: {
        marginLeft: '6px',
        marginRight: '6px',
        fontSize: '0.875rem',
        fontWeight: 400
      }
    },
    MuiStepLabel: {
      iconContainer: {
        paddingRight: 0
      },
      label: {
        display: 'flex',
        fontWeight: 500
      }
    },
    MuiFormLabel: {
      asterisk: {
        color: '#d04437'
      }
    },
    MuiTab: {
      root: {
        textTransform: 'none'
      }
    }
  }
};

const themesOptions = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiInputBase: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: colors.blueGrey[600]
          }
        }
      }
    },
    palette: {
      type: 'light',
      action: {
        active: colors.blueGrey[600]
      },
      background: {
        default: colors.common.white,
        dark: '#F7F7F8',
        paper: colors.common.white
      },
      primary: {
        main: '#fdb515'
      },
      secondary: {
        main: '#333'
      },
      success: {
        main: '#00875a'
      },
      error: {
        main: '#d04437'
      },
      warning: {
        main: '#DFAE1B'
      },
      alert: {
        main: '#ea7d24'
      },
      info: {
        main: '#0052cc'
      },
      text: {
        primary: colors.blueGrey[900],
        secondary: colors.blueGrey[400]
      },
    },
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#282C34',
        dark: '#1c2025',
        paper: '#282C34'
      },
      primary: {
        main: '#8a85ff'
      },
      secondary: {
        main: '#8a85ff'
      },
      text: {
        primary: '#e6e5e8',
        secondary: '#adb0bb'
      }
    },
    shadows: strongShadows
  },
  {
    name: THEMES.UNICORN,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#2a2d3d',
        dark: '#222431',
        paper: '#2a2d3d'
      },
      primary: {
        main: '#a67dff'
      },
      secondary: {
        main: '#a67dff'
      },
      text: {
        primary: '#f6f5f8',
        secondary: '#9699a4'
      }
    },
    shadows: strongShadows
  }
];

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
