import React, {
  useRef,
  useEffect,
  useState,
  memo
} from 'react';
import clsx from 'clsx';
import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import {
  Columns as ColumnsIcon
} from 'react-feather';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  menu: {
    width: 256,
    maxWidth: '100%'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  }
}));

const GroupByField = ({
  className,
  columns,
  groupBy,
  setGroupBy,
  setTableGroupBy,
  ...rest
}) => {
  const classes = useStyles();
  const moreRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);

  useEffect(() => {
    if (groupBy) {
      setTableGroupBy([groupBy]);
    }
  }, [groupBy, setTableGroupBy]);

  const handleMenuOpen = () => {
    setOpenMenu(true);
  };

  const handleMenuClose = () => {
    setOpenMenu(false);
  };

  const handleGroupBy = (accessor) => {
    if (accessor === groupBy) {
      // Remove group by
      setTableGroupBy([]);
      setGroupBy('');
    } else {
      setTableGroupBy([accessor]);
      setGroupBy(accessor);
    }

    handleMenuClose();
  };

  return (
    <React.Fragment>
      <Button
        className={clsx(
          classes.root,
          className,
          groupBy ? classes.active : null
        )}
        variant="outlined"
        onClick={handleMenuOpen}
        ref={moreRef}
        endIcon={<ColumnsIcon size="12" />}
      >
        Group by
      </Button>
      <Menu
        anchorEl={moreRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        onClose={handleMenuClose}
        open={openMenu}
        PaperProps={{ className: classes.menu }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        {columns.map(column => (
          <MenuItem
            key={column.accessor}
            onClick={() => handleGroupBy(column.accessor)}
          >
            <ListItemText
              primary={column.Header}
              primaryTypographyProps={{
                variant: 'body2',
                className: column.accessor === groupBy ? classes.bold : null
              }}
            />
            {column.accessor === groupBy ? (
              <ListItemIcon>
                <HighlightOffIcon />
              </ListItemIcon>
            ) : null}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
};

export default memo(GroupByField);
