import React, {
  useRef,
  useState
} from 'react';
import {
  Avatar,
  Box,
  ButtonBase,
  ListItemText,
  Menu,
  MenuItem,
  makeStyles
} from '@material-ui/core';
import getInitials from '../../utils/getInitials';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  popover: {
    width: 200
  }
}));

const Account = ({
  user,
  onLogout
}) => {
  const classes = useStyles();
  const ref = useRef(null);
  // const { user, isAuthenticated, logout } = useAuth0();
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleLogout = async () => {
  //   logout({ returnTo: window.location.origin + process.env.PUBLIC_URL });
  // };

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
      >
        <Avatar
          alt="User"
          className={classes.avatar}
        >
          { user ? getInitials(user.name) : null}
        </Avatar>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem onClick={onLogout}>
          <ListItemText
            primary="Logout"
            primaryTypographyProps={{
              variant: 'body2',
            }}
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default Account;
