import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardHeader,
  Divider,
  CardMedia,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  item: {
    textAlign: 'center',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  }
}));

const ImageCard = ({
  className,
  title,
  src,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
    >
      <CardHeader
        title={title}
      />
      <Divider />
      <CardMedia
        component="img"
        image={src}
      />
    </Card>
  );
};

ImageCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
};

export default ImageCard;
