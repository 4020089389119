import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  Divider,
  Typography,
  darken,
  makeStyles,
} from '@material-ui/core';
import ButtonWithLoader from './ButtonWithLoader';

const useStyles = makeStyles((theme) => ({
  root: {},
  cancel: {
    marginRight: theme.spacing(2),
    color: theme.palette.error.main,
    fontWeight: theme.typography.fontWeightRegular
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.26),
    },
  }
}));

const DeleteDialog = ({
  className,
  title,
  loading,
  open,
  onDelete,
  onCancel
}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      maxWidth="sm"
      fullWidth
      onClose={onCancel}
      open={open}
    >
      <Box p={2}>
        <Typography
          align="center"
          gutterBottom
          variant="h3"
          color="textPrimary"
        >
          {title}
        </Typography>
      </Box>
      <Divider />
      <Box p={3}>
        <Typography
          align="center"
          gutterBottom
          variant="body2"
          color="textSecondary"
        >
          Are you sure you want to delete this item? This can’t be undone.
        </Typography>
      </Box>
      <Divider />
      <Box
        p={2}
        display="flex"
        alignItems="center"
      >
        <Box flexGrow={1} />
        <Button
          className={classes.cancel}
          onClick={onCancel}
        >
          Cancel
        </Button>
        <ButtonWithLoader
          className={classes.deleteButton}
          disableElevation
          isLoading={loading}
          onClick={onDelete}
        >
          Delete
        </ButtonWithLoader>
      </Box>
    </Dialog>
  );
};

DeleteDialog.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  onDelete: PropTypes.func,
  onCancel: PropTypes.func
};

DeleteDialog.defaultProps = {
  open: false,
  onDelete: () => { },
  onCancel: () => { },
};

export default DeleteDialog;
