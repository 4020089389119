import React, { useMemo, useState } from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import { useTable, useGroupBy, useExpanded } from 'react-table';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  makeStyles,
  TableFooter
} from '@material-ui/core';
import {
  Pagination,
  Skeleton
} from '@material-ui/lab';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@material-ui/icons';
import {
  Search as SearchIcon,
} from 'react-feather';
import { NUM_SKELETON_ROWS } from '../constants';
import getObjWithVal from '../utils/getObjWithVal';
// import { useDispatch } from 'src/store';
// import { openDeleteModal } from 'src/slices/common';
import GroupByField from './GroupByField';
import SelectFilter from './SelectFilter';
// import TagFilter from './TagFilter';

const sortOptions = [
  {
    value: 'updatedAt|desc',
    label: 'Last update (newest first)'
  },
  {
    value: 'updatedAt|asc',
    label: 'Last update (oldest first)'
  },
  {
    value: 'orders|desc',
    label: 'Total orders (high to low)'
  },
  {
    value: 'orders|asc',
    label: 'Total orders (low to high)'
  }
];

const applyLookups = (records, lookups) => {
  return records.map((record) => {
    let lookedUpFields = {};
    Object.keys(record).forEach((property) => {
      if (property in lookups) {
        const lookup = lookups[property];
        const lookedUpObj = getObjWithVal(
          lookup.choices,
          lookup.lookupField,
          record[property]
        );

        if (lookedUpObj) {
          const value = lookup.display(lookedUpObj);
          lookedUpFields[property] = value;
        }
      }
    });

    return {
      ...record,
      ...lookedUpFields
    };
  });
};

const applyFilters = (records, query, filters) => {
  return records.filter((record) => {
    let matches = true;

    if (query) {
      let containsQuery = false;

      Object.keys(record).forEach((property) => {
        if (record[property] && record[property].toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const filter = filters[key];

      if (!filter.length) return;

      if (key === 'tags') {
        const tags = record[key];
        const tagFilter = filter.filter(f => f.name || f.value);
        if (!tagFilter.length) return;

        matches = tagFilter.every(f =>
          tags.filter(t =>
            (f.name === t.name || !f.name) && (f.value === t.value || !f.value)
          ).length
        );

        return;
      }

      if (!filter.includes(record[key])) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (records, page, limit) => {
  return records.slice(page * limit, page * limit + limit);
};

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }

  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const applySort = (records, sort, orderBy, order) => {
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = records.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const getInitialFilters = (filterConfig, defaultFilters = {}) => {
  let filters = Object.keys(filterConfig).reduce((result, item) => {
    result[item] = [];
    return result;
  }, {});

  // Apply any default filters
  filters = {
    ...filters,
    ...defaultFilters,
  };

  return filters;
};

const EnhancedTableHead = ({
  classes,
  headerGroups,
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
  ...rest
}) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      {headerGroups.map(headerGroup => (
        <TableRow {...headerGroup.getHeaderGroupProps()}>
          <TableCell padding="checkbox">
            {/* <Checkbox
              // checked={selectedAllRecords}
              // indeterminate={selectedSomeRecords}
              onChange={onSelectAllClick}
              style={{
                transform: "scale(0.8)"
              }}
            /> */}
          </TableCell>
          {headerGroup.headers.map(column => (
            <TableCell
              sortDirection={orderBy === column.id ? order : false}
              {...column.getHeaderProps()}
            >
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
              >
                {column.render('Header')}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell align="right">
            Actions
          </TableCell>
        </TableRow>
      ))}
    </TableHead>
  );
}

// EnhancedTableHead.propTypes = {
//   classes: PropTypes.object.isRequired,
//   numSelected: PropTypes.number.isRequired,
//   onRequestSort: PropTypes.func.isRequired,
//   onSelectAllClick: PropTypes.func.isRequired,
//   order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//   orderBy: PropTypes.string.isRequired,
//   rowCount: PropTypes.number.isRequired,
// };

const useStyles = makeStyles((theme) => ({
  root: {},
  search: {
    backgroundColor: theme.palette.background.default
  },
  resetButton: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  tableAction: {
    marginRight: theme.spacing(1)
  },
  tableFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '52px'
  },
  tableLimitFormControl: {
    height: '32px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3)
  },
  tableLimitSelect: {
    paddingTop: '7.5px',
    paddingBottom: '7.5px',
  },
  bulkAction: {
    marginLeft: theme.spacing(2)
  },
  actionCell: {
    paddingRight: theme.spacing(1),
    textAlign: 'right'
  }
}));

function useControlledState(state, { instance }) {
  return useMemo(() => {
    if (state.groupBy.length) {
      return {
        ...state,
        hiddenColumns: [...state.hiddenColumns, ...state.groupBy].filter(
          (d, i, all) => all.indexOf(d) === i
        ),
      }
    }
    return state
  }, [state])
}

const ResultsTable = ({
  className,
  columns,
  records,
  lookups,
  actions,
  filterConfig,
  defaultFilters,
  additionalFilterFields,
  canGroupBy,
  isLoaded,
  ...rest
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState(getInitialFilters(filterConfig, defaultFilters));
  const [groupBy, setGroupBy] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [sort] = useState(sortOptions[0].value);

  const lookedUpRecords = applyLookups(records, lookups);
  const filteredRecords = applyFilters(lookedUpRecords, query, filters);
  const sortedRecords = applySort(filteredRecords, sort, orderBy, order);
  const paginatedRecords = applyPagination(sortedRecords, page, limit);
  // const selectedSomeRecords = selectedRecords.length > 0 && selectedRecords.length < records.length;
  // const selectedAllRecords = selectedRecords.length === records.length;

  const data = useMemo(
    () => isLoaded ? paginatedRecords : Array(NUM_SKELETON_ROWS).fill({}),
    // eslint-disable-next-line
    [isLoaded, records, query, filters, orderBy, order, lookups, page, limit]
  );

  const tableInstance = useTable(
    {columns, data},
    useGroupBy,
    useExpanded,
    hooks => {
      hooks.useControlledState.push(useControlledState)
      hooks.visibleColumns.push((columns, { instance }) => {
        if (!instance.state.groupBy.length) {
          return columns;
        }

        return [
          {
            id: 'expander', // Make sure it has an ID
            // Build our expander column
            Header: ({ allColumns, state: { groupBy } }) => {
              return groupBy.map(columnId => {
                const column = allColumns.find(d => d.id === columnId)

                return (
                  <span {...column.getHeaderProps()}>
                    {column.render('Header')}{' '}
                  </span>
                )
              })
            },
            Cell: ({ row }) => {
              if (row.canExpand) {
                const groupedCell = row.allCells.find(d => d.isGrouped)

                return (
                  <span
                    {...row.getToggleRowExpandedProps({
                      style: {
                        // We can even use the row.depth property
                        // and paddingLeft to indicate the depth
                        // of the row
                        paddingLeft: `${row.depth * 2}rem`,
                      },
                    })}
                  >
                    <IconButton
                      style={{marginRight: '5px'}}
                      aria-label="expand row"
                      size="small"
                    >
                      {row.isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                    {groupedCell.render('Cell')}{' '}
                    ({row.subRows.length} items)
                  </span>
                )
              }

              return null
            },
          },
          ...columns,
        ]
      })
    }
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllRecords = (event) => {
    setSelectedRecords(event.target.checked
      ? rows.map((row) => row.id)
      : []);
  };

  const handleSelectOneRecord = (event, recordId) => {
    if (!selectedRecords.includes(recordId)) {
      setSelectedRecords((prevSelected) => [...prevSelected, recordId]);
    } else {
      setSelectedRecords((prevSelected) => prevSelected.filter((id) => id !== recordId));
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1); // Zero-indexed
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const handleResetTable = () => {
    setQuery('');
    setFilters(
      getInitialFilters(filterConfig)
    );
    setGroupBy('');
  };

  const isFilteredOrGrouped = Boolean(query || Object.values(filters).flat().length || groupBy);

  return (
    <React.Fragment>
      <Box
        display="flex"
        alignItems="center"
        pb={2}
      >
        <Box
          display="flex"
          flex="1 1 auto"
        >
          <TextField
            className={clsx(classes.tableAction, classes.search)}
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SvgIcon
                    fontSize="inherit"
                    color="action"
                  >
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            onChange={handleQueryChange}
            placeholder="Search"
            value={query}
            variant="outlined"
          />
          {Object.keys(filterConfig).map(key => (
            <SelectFilter
              key={key}
              className={classes.tableAction}
              filterName={key}
              label={filterConfig[key].label}
              options={filterConfig[key].options}
              selectedOptions={filters[key]}
              getOptionLabelName={filterConfig[key].display}
              setFilters={setFilters}
            />
          ))}
          {additionalFilterFields.map(FilterField => (
            <FilterField
              className={classes.tableAction}
              filters={filters}
              setFilters={setFilters}
            />
          ))}
          {canGroupBy && (
            <GroupByField
              columns={columns}
              groupBy={groupBy}
              setGroupBy={setGroupBy}
              setTableGroupBy={tableInstance.setGroupBy}
            />
          )}
        </Box>
        {isFilteredOrGrouped && (
          <Box
            flex="0 0 auto"
            alignSelf="flex-start"
          >
            <Button
              className={classes.resetButton}
              variant="outlined"
              onClick={() => handleResetTable()}
            >
              Reset
            </Button>
          </Box>
        )}
      </Box>

      <Card
        className={clsx(classes.root, className)}
        {...rest}
      >
        <PerfectScrollbar>
          <Box
            minWidth={700}
          >
            <Table {...getTableProps()}>
              <EnhancedTableHead
                classes={classes}
                headerGroups={headerGroups}
                numSelected={selectedRecords.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllRecords}
                onRequestSort={handleRequestSort}
                rowCount={records.length}
              />
              <TableBody {...getTableBodyProps()}>
                {rows.map(row => {
                  const isRecordSelected = selectedRecords.includes(row.id);
                  prepareRow(row);

                  return (
                    <TableRow {...row.getRowProps()}>
                      <TableCell padding="checkbox">
                        {row.canExpand ? null : (
                          <Checkbox
                            checked={isRecordSelected}
                            onChange={(event) => handleSelectOneRecord(event, row.id)}
                            value={isRecordSelected}
                            style={{
                              transform: "scale(0.8)"
                            }}
                          />
                        )}
                      </TableCell>
                      {row.cells.map(cell => {
                        return (
                          <TableCell
                            {...cell.getCellProps()}
                          >
                            {isLoaded ? cell.render('Cell') : <Skeleton />}
                          </TableCell>
                        )
                      })}
                      <TableCell
                        className={classes.actionCell}
                      >
                        {actions.map((action, i) => (
                          <IconButton
                            key={`action-${i}`}
                            onClick={() => action.onClick(row.original?.id)}
                            disabled={!isLoaded}
                          >
                            <SvgIcon fontSize="small">
                              {action.icon}
                            </SvgIcon>
                          </IconButton>
                        ))}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
      <TableFooter
        className={classes.tableFooter}
      >
        <Box>
          <Typography
            variant="body2"
            color="textSecondary"
          >
            {filteredRecords.length} records
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            className={classes.selectLabel}
            color="inherit"
            variant="body2">
            Show
          </Typography>

          <FormControl
            className={classes.tableLimitFormControl}
            variant="outlined"
          >
            <Select
              classes={{
                root: classes.tableLimitSelect
              }}
              value={limit}
              onChange={handleLimitChange}
            >
              {[5, 10, 25, 50].map(rowsPerPage => (
                <MenuItem
                  key={rowsPerPage}
                  value={rowsPerPage}
                >
                  {rowsPerPage}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Pagination
            color="secondary"
            count={Math.ceil(filteredRecords.length / limit)}
            onChange={handlePageChange}
            shape="rounded"
          />
        </Box>
      </TableFooter>
    </React.Fragment>
  );
};

ResultsTable.propTypes = {
  className: PropTypes.string,
  records: PropTypes.array.isRequired,
  additionalFilterFields: PropTypes.array,
  canGroupBy: PropTypes.bool
};

ResultsTable.defaultProps = {
  records: [],
  filterConfig: {},
  additionalFilterFields: [],
  canGroupBy: false
};

export default ResultsTable;
