import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Link,
  Typography,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(2)
  },
  link: {
    minWidth: '150px'
  },
  logo: {
    height: '1.5rem'
  }
}));

const PoweredBy = ({
  className,
  href,
  src,
  alt
}) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <Link
        className={classes.link}
        href={href}
        target="_blank"
        underline="none"
        rel="noopener"
      >
        <Box
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            mr={1}
            display="inline-flex"
          >
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ fontSize: '0.75rem' }}
            >
              Powered by
            </Typography>
          </Box>
          <img
            className={classes.logo}
            alt={alt}
            src={src}
          />
        </Box>
      </Link>
    </div>
  );
}

export default PoweredBy;
