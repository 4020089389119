import React from 'react';
import PropTypes from 'prop-types';
import { useAuth0 } from "@auth0/auth0-react";

const AuthGuard = ({ children }) => {
  const {
    // error,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname
      }
    });
  }

  // TODO: Get new access token if expired

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
