import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Button,
  CircularProgress,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  button: {
    minHeight: '36px',
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: theme.spacing(-1.125),
    marginLeft: theme.spacing(-1.125)
  },
}));

const ButtonWithLoader = ({
  className,
  children,
  isLoading,
  CircularProgressProps,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Button
        className={clsx(classes.button, className)}
        fullWidth
        disabled={isLoading}
        {...rest}
      >
        {isLoading ? null : children}
        {isLoading && (
          <CircularProgress
            className={classes.buttonProgress}
            thickness={5.0}
            size={18}
            {...CircularProgressProps}
          />
        )}
      </Button>
    </div>
  );
}

ButtonWithLoader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
  CircularProgressProps: PropTypes.object
};

ButtonWithLoader.defaultProps = {
  isLoading: false,
  CircularProgressProps: {}
};

export default ButtonWithLoader;
