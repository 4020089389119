import React, {
    useRef,
    useState,
    memo
  } from 'react';
  import {
    Tooltip,
    IconButton,
    Menu,
    MenuItem,
  } from '@material-ui/core';
  import { Calendar as CalendarIcon } from 'react-feather';

  const DateSelectButton = (props) => {
    const actionRef = useRef(null);
    const [isMenuOpen, setMenuOpen] = useState(false);

    return (
      <React.Fragment>
        <Tooltip title="Date range">
          <IconButton
            onClick={() => setMenuOpen(true)}
            ref={actionRef}
            {...props}
          >
            <CalendarIcon size="20" />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={actionRef.current}
          onClose={() => setMenuOpen(false)}
          open={isMenuOpen}
          getContentAnchorEl={null}
          anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
          }}
          transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
          }}
        >
          {Object.keys(props.options).map((value) => (
          <MenuItem
            key={value}
            onClick={() => props.onSelect(value)}
          >
            {props.options[value]}
          </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  };

export default memo(DateSelectButton);
